import { Button, Image, Typography, Spin, notification } from 'antd';
import CategoryCard from './CategoryCard';
import TopSellingProductCard from './TopSellingProductCard';
import NewInClothingProductCard from './NewInClothingProductCard';
import NewInCardsProductCard from './NewInCardsProductCard';
import { Images } from '../../images';
import React, { useEffect } from 'react';
import { actions } from '../../contexts/category/actions';
import { useCategoryDispatch, useCategoryState } from '../../contexts/category';
import useDebounce from '../UseDebounce';
import { useNavigate } from 'react-router-dom';
import routes from '../../helpers/routes';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Fade } from 'react-awesome-reveal';
import HelmetComponent from '../Helmet/HelmetComponent';
import { SEO } from '../../helpers/seoConstant';
import { BANNER, collectibleTypes } from '../../helpers/constants';
import { bannerArrow } from '../../images/SVGComponents';

// ----------------------------------------------------------

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

// import required modules
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';

const MarketPlace = ({ user, isAuthenticated }) => {
  const limit = 10,
    offset = 0;
  const navigate = useNavigate();
  const dispatch = useCategoryDispatch();
  const debouncedSearchTerm = useDebounce('', 1000);
  const { iscategorysLoading } = useCategoryState();

  useEffect(() => {
    if (isAuthenticated) {
      const loginCount = localStorage.getItem('loginCount');
      // If loginCount is not set, it means this is the first login
      if (!loginCount) {
        // Show the notification
        notification.open({
          description:
            'Click here to review some updates on your Assets and Orders!',
          icon: null,
          btn: (
            <Button
              type="primary"
              onClick={() => navigateToUserProfile()}
              style={{
                borderRadius: '20px',
                color: '#fff',
              }}
            >
              Explore now
            </Button>
          ),
          placement: 'bottomRight',
          style: {
            borderRadius: '12px',
          },
        });
        // Set loginCount to 1 to indicate the user has logged in at least once
        localStorage.setItem('loginCount', '1');
      }
    }
  }, [isAuthenticated, navigate]);

  const navigateToUserProfile = () => {
    navigate(
      `${routes.MarketplaceUserProfile.url.replace(
        ':commonName',
        user.commonName
      )}?tab=my-activity`
    );
  };

  useEffect(() => {
    actions.fetchCategories(dispatch, limit, offset, debouncedSearchTerm);
  }, [dispatch, limit, offset, debouncedSearchTerm]);

  const linkUrl = window.location.href;
  // const navRoute = routes.MarketplaceCategoryProductList.url.replace(':category', 'All');

  const ButtonElement = ({ desktopText, mobileText, url }) => (
    <div className="relative flex top-[156px] sm:top-[250px] xl:top-[65%] 3xl:top-[70%] left-[4%] sm:left-[7.5%] md:left-[7%] md:top-60 z-50">
      <Button
        id="viewMore"
        onClick={() => {
          navigate(url);
          sessionStorage.setItem('scrollPosition', 0);
        }}
        className="gradient-button border-0 h-auto md:h-11 border-primary bg-white text-primary hover:text-white"
      >
        <div className="flex items-center">
          <div className="hidden sm:block font-semibold text-lg banner-btn-text">
            {desktopText}
          </div>
          <div className="sm:hidden font-semibold text-base banner-btn-text">
            {mobileText}
          </div>
          <span className="ml-1">{bannerArrow}</span>
        </div>
      </Button>
    </div>
  );

  const CarouselElement = ({ scrollT }) => (
    <Swiper
      spaceBetween={30}
      effect={'fade'}
      navigation={false}
      centeredSlides={true}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, EffectFade, Navigation, Pagination]}
      className="mySwiper"
    >
      {BANNER.map((item, index) => (
        <SwiperSlide>
          <div
            key={index}
            className="no-select relative p-2 h-[222px] sm:h-[380px] 3xl:h-[480px] mx-1 md:mx-2 md:mt-6 lg:mx-3"
          >
            <ButtonElement
              desktopText={item.desktopText}
              mobileText={item.mobileText}
              url={item.link}
            />
            {item.text}
            <div className="sm:hidden">
              <img
                alt={item.alt}
                title={item.title}
                className="no-select absolute inset-0 z-10 h-[222px] w-[96%] rounded-md md:rounded-[14px] drop-shadow-md mx-auto"
                height={380}
                width="100%"
                src={item.mobileImg}
                preview={false}
              />
            </div>
            <div className="hidden sm:block md:hidden">
              <img
                alt={item.alt}
                title={item.title}
                className="no-select absolute inset-0 z-10 h-[380px] w-[96%] rounded-md md:rounded-[14px] drop-shadow-md mx-auto"
                height={380}
                width="100%"
                src={item.tabletImg}
                preview={false}
              />
            </div>
            <div className="hidden md:block lg:hidden">
              <img
                alt={item.alt}
                title={item.title}
                className="no-select absolute inset-0 z-10 md:h-[330px] w-[98%] rounded-md md:rounded-[14px] drop-shadow-md mx-auto"
                height={380}
                width="100%"
                src={item.laptopImg}
                preview={false}
              />
            </div>
            <div className="hidden lg:block">
              <img
                alt={item.alt}
                title={item.title}
                className="no-select absolute inset-0 z-10 lg:h-[330px] 3xl:h-[480px] w-[98%] rounded-md md:rounded-[14px] drop-shadow-md mx-auto"
                height={380}
                width="100%"
                src={item.desktopImg}
                preview={false}
              />
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );

  const handleSubcategoryRedirect = (value) => {
    navigate(`/c/Collectibles?type=${value}`);
  };

  return (
    <>
      <HelmetComponent
        title={SEO.TITLE_META}
        description={SEO.DESCRIPTION_META}
        link={linkUrl}
      />
      <div className="w-full flex flex-wrap gap-4 px-4 py-3 md:px-8 bg-[#E1E2F2]">
        {collectibleTypes.map(({ label, value }) => (
          <span
            key={value}
            className="cursor-pointer px-4 py-2 bg-gray-100 font-bold rounded-md text-center hover:bg-blue-500 hover:opacity-40 hover:scale-110 transition-all duration-300 ease-out shadow-md hover:shadow-xl"
            onClick={() => handleSubcategoryRedirect(value)}
          >
            {label}
          </span>
        ))}
      </div>

      <Fade triggerOnce className="mt-6">
        <CarouselElement scrollT={130} />
      </Fade>
      {iscategorysLoading ? (
        <div className="h-96 flex justify-center items-center">
          <Spin spinning={iscategorysLoading} size="large" />
        </div>
      ) : (
        <>
          <div className="px-3 md:px-0 py-30 mt-6 md:mt-10 mb-10">
            <TopSellingProductCard
              title={'Trending Items'}
              categories={[]}
              collectibleTypes={[]}
            />
          </div>
          <div className="px-3 md:px-0 py-30 mt-6 md:mt-10 mb-10">
            <NewInClothingProductCard
              title={'New in Clothing'}
              categories={['Clothing']}
              collectibleTypes={[]}
            />
          </div>
          <div className="px-3 md:px-0 py-30 mt-6 md:mt-10 mb-10">
            <NewInCardsProductCard
              title={'New in Cards'}
              categories={['Collectibles']}
              collectibleTypes={['Cards']}
            />
          </div>
          <h3 className="text-center text-gray-500 mt-8 mb-8">
            Is there an item you would like to see on the marketplace?
            <a
              href="https://forms.gle/biuEtUHrFdLpX1d36"
              rel="noreferrer"
              target="_blank"
              className="text-blue"
            >
              {' '}
              Let us know!
            </a>
          </h3>
        </>
      )}
    </>
  );
};

export default MarketPlace;
